import { useEffect, useState } from "react";
import { CalendarEvent } from "../../model/calendar-event"

export const CalendarDayItem = (props: {
    type: 'today' | 'selected' | 'default' 
    date: Date
    activeEvents: CalendarEvent[]
}) => {
    const [dayOfWeek, setDayOfWeek] = useState('')
    useEffect(() => {
        setDayOfWeek(props.date.toLocaleDateString('de', { weekday: 'long' }).toUpperCase().substring(0,3))
    }, [props.date]);
      
    return (
        <div className={ `calendar-day-item calendar-day-${props.type}`}>
            <div className="calendar-day-card">
                <div className="cal-header-day-short">{ dayOfWeek }</div>
                <div className="cal-header-day">{ props.date.getDate() }</div>
            </div>
            <div className="calendar-day-event-bullets"></div>
        </div>
    );
}