import "./info-panel.css";
import EmojiTransportationOutlinedIcon from "@mui/icons-material/EmojiTransportationOutlined";
type Props = {
  title: string;
  children: any;
};

export const InfoPanel = ({ title, children }: Props) => (
  <div className="into-panel">
    <div className="info-panel-header">
      <div className="info-panel-header-icon">
        <EmojiTransportationOutlinedIcon />
      </div>
      <div className="info-panel-header-title">{title}</div>
    </div>
    <div className="info-panel-content">{children}</div>
  </div>
);
