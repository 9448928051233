import { FunctionComponent, useState } from "react";
import Html5QrcodePlugin from "../shared/html-qr-scanner";
import { useNavigate } from "react-router-dom";
import { QrButton } from "../shared/qr-button/qr-button";
import "./on-boarding.css";

interface Props {}

export const OnBoarding: FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const [activateQrCodeScanner, setActivateQrCodeScanner] = useState(false);
  // navigate(`/on-boarding-details/1`, { replace: true });
  return (
    <>
      <div className="onboarding-title">Anmeldung</div>
      {!activateQrCodeScanner ? (
        <>
          <p className="onboarding-info first-line">Drücken Sie auf:</p>
          <QrButton
            onClick={() => {
              setActivateQrCodeScanner(true);
            }}
          />
          <p className="onboarding-info">
            Danach, zeigen Sie mit der <b>Handykamera auf den QR-Code</b>, der
            sich
            <b> auf der Waschmaschine</b> befindet, um sich anzumelden.
          </p>
        </>
      ) : (
        <Html5QrcodePlugin
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={(data: any) => {
            navigate(`/on-boarding-details/${data}`, { replace: true });
          }}
          qrCodeErrorCallback={function (error: any): void {
            console.error("Camera error", error);
          }}
        />
      )}
    </>
  );
};
