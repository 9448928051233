import React from 'react';
import "@fontsource/dm-sans"
import './App.css';
import { HomeScreen } from './home-screen/home-screen';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Calendar } from './calendar/clanedar';
import { Layout } from './layout/layout';
import { LoginDialog } from './auth/login-dialog/login-dialog';
import { CalendarBookingPage } from './calendar/calendar-booking-page/calendar-booking-page';
import { OnBoarding } from './on-boarding/on-boarding';
import { OnBoardingDetails } from './on-boarding/on-boarding-details/on-boarding-details';

function App() {
  return (
    <div className="App">
      <LoginDialog open={true} />
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomeScreen />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="on-boarding" element={<OnBoarding />} />
          <Route path="on-boarding-details/:id" element={<OnBoardingDetails />} />
          <Route path="booking" element={<CalendarBookingPage />} />
          <Route path="*" element={<HomeScreen />} />
        </Route>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
