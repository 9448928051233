import Badge from "@mui/material/Badge";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import "./action-header.css";

type Props = {
  notificationCount: number;
};

export const ActionHeader = ({ notificationCount }: Props) => (
  <div className="action-header-bar">
    <Badge badgeContent={notificationCount} color="primary">
      <EmailOutlinedIcon color="action" />
    </Badge>
  </div>
);
