import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import "./back-nav-header.css";

type Props = { backlink: string; children: any };

export const BackNavHeader = ({ backlink, children }: Props) => (
  <header className="back-nav-header">
    <Link to={`${backlink}`}>
      <ArrowBackIcon />
    </Link>
    <div className="heading-title">{children}</div>
  </header>
);
