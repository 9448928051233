import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useState } from "react";


const handleLogin = (user: string, pass: string, setOpen: (open: boolean) => void) => {
  if (user === 'w' && pass === 's') 
    setOpen(false);
}


export const LoginDialog = (props: { open: boolean }) => {
  const [userName, setUserName] = useState('');
  const [pass, setPass] = useState('');
  const [open, setOpen] = useState(props.open);
  
  return (
  <Dialog open={open} disableEscapeKeyDown={ true } fullScreen={ true }>
    <DialogTitle>Login</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Login Daten eingeben
      </DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label="Username"
        type="text"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
        fullWidth
        variant="standard"
      />
      <TextField
        margin="dense"
        id="pass"
        label="Password"
        type="password"
        value={pass}
        onChange={(e) => setPass(e.target.value)}
        fullWidth
        variant="standard"
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => handleLogin(userName, pass, setOpen)}>Login</Button>
    </DialogActions>
  </Dialog>
)};