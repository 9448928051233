import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

export interface CalendarDialogProps {
  open: boolean;
  // selectedValue: string;
  onClose: (value: string) => void;
}

export const CalendarEventActionDialog = (props: CalendarDialogProps) => {
  const { onClose } = props;

  return (
    <Dialog 
    onClose={(value: string) => onClose(value)} 
    open={props.open}>
    <DialogTitle>Bearbeiten</DialogTitle>
    <List sx={{ pt: 0 }}>
        <ListItem autoFocus button onClick={() => onClose('edit')}>
          <ListItemAvatar>
            <Avatar>
              <EditOutlinedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Termin bearbeiten" />
        </ListItem>
      <ListItem button onClick={() => onClose('delete')}>
        <ListItemAvatar>
          <Avatar>
            <DeleteOutlineOutlinedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Löschen" />
      </ListItem>
    </List>
  </Dialog>
)};