import { useState } from "react";
import { CalendarDayChooseItem } from "./calendar-choose-item/calendar-choose-item";
import { CalendarDayItem } from "./calendar-day-item/calendar-day-item";

import "./calendar-header.css";
import { ActionHeader } from "../../shared/action-header";

const DAYS_TO_SHOW = 14;
const TODAY = new Date();

export type CalendarHeaderProps = {
  dateSelected: (selectedDate: Date) => void;
};

const getDay = (day: Date) =>
  day.toLocaleString("de", { weekday: "long", month: "long", day: "numeric" });
const getDayByOffset = (offset: number) => {
  const date = new Date();
  date.setDate(date.getUTCDate() + offset);
  return date;
};

export const CalendarHeader = ({ dateSelected }: CalendarHeaderProps) => {
  const [selected, setSelected] = useState<number>(0);
  const [currentDate, setCurrentDate] = useState<Date>(TODAY);

  return (
    <>
      <ActionHeader notificationCount={0} />
      <header className="header-container" aria-label="Wahlen sie einen tag">
        <div className="calendar-today-label">Heute</div>
        <div className="calendar-today">{getDay(currentDate)}</div>
        <div className="calendar-day-collection">
          {[...Array(DAYS_TO_SHOW)].map((value, index) => (
            <div
              className={selected === index ? "calendar-day-selected" : ""}
              key={index}
              onClick={() => {
                setSelected(index);
                setCurrentDate(getDayByOffset(index));
                dateSelected(getDayByOffset(index));
              }}
            >
              <CalendarDayItem
                type={index === 0 ? "today" : "default"}
                date={getDayByOffset(index)}
                activeEvents={[]}
              />
            </div>
          ))}
          <CalendarDayChooseItem />
        </div>
      </header>
    </>
  );
};
