import { Html5Qrcode, Html5QrcodeScanType } from "html5-qrcode";
import React from "react";

const qrcodeRegionId = "html5qr-code-full-region";

class Html5QrcodePlugin extends React.Component {
  render() {
    return <div id={qrcodeRegionId} />;
  }

  componentWillUnmount() {
    // TODO(mebjas): See if there is a better way to handle
    //  promise in `componentWillUnmount`.
    // this.html5QrcodeScanner.clear().catch(error => {
    //     console.error("Failed to clear html5QrcodeScanner. ", error);
    // });
    this.html5QrcodeScanner
      ?.stop()
      .then((ignore) => {
        // QR Code scanning is stopped.
      })
      .catch((err) => {
        // Stop failed, handle it.
      });
  }

  componentDidMount() {
    // Creates the configuration object for Html5QrcodeScanner.
    function createConfig(props) {
      var config = {};
      if (props.fps) {
        config.fps = props.fps;
      }
      if (props.qrbox) {
        config.qrbox = props.qrbox;
      }
      if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio;
      }
      if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
      }
      config.rememberLastUsedCamera = true;
      config.supportedScanTypes = [Html5QrcodeScanType.SCAN_TYPE_CAMERA];
      return config;
    }

    var config = createConfig(this.props);

    // Suceess callback is required.
    if (!this.props.qrCodeSuccessCallback) {
      throw "qrCodeSuccessCallback is required callback.";
    }

    Html5Qrcode.getCameras()
      .then((devices) => {
        if (devices && devices.length) {
          this.html5QrcodeScanner = new Html5Qrcode(qrcodeRegionId);
          this.html5QrcodeScanner.start(
            { facingMode: "environment" },
            config,
            (data) => {
              // this.html5QrcodeScanner.stop();
              this.props.qrCodeSuccessCallback(data);
            },
            this.props.qrCodeErrorCallback
          );
        }
      })
      .catch((err) => {
        console.error(
          "Keine Kamera erkannt. Haben Sie die Rechte erteilt ?",
          err
        );
      });
  }
}

export default Html5QrcodePlugin;
