import { Link, useParams } from "react-router-dom";
import { BackNavHeader } from "../../shared/header/back-nav-header/back-nav-header";
import OnBoardingIntro from "../../assets/img/onboarding-teaser.png";
import { InfoPanel } from "../../shared/info-panel/info-panel";
import { InlineMultiSelector } from "../../shared/inline-multi-selector/inline-multi-selector";
import Checkbox from "@mui/material/Checkbox";
import "./on-boarding-details.css";
import Fab from "@mui/material/Fab";
import ArrowForward from "@mui/icons-material/ArrowForward";

export const OnBoardingDetails = () => {
  const { id } = useParams();

  return (
    <>
      <BackNavHeader backlink="/calendar">Registrierung</BackNavHeader>
      {/* <img className="intro-image" src={OnBoardingIntro} alt="Intro Bild" /> */}
      <InfoPanel title="Krippenstrasse 38, Bern">
        <div className="info-panel-footer-line1">Waschmaschine - Rechts</div>
        <div className="info-panel-footer-line2">Blaue Chipkarte</div>
      </InfoPanel>
      <InlineMultiSelector></InlineMultiSelector>
      <div className="agreement-container">
        <div className="inline-multi-title">Termin freigabe:</div>
        <div className="agreement-content-container">
          <div className="agreement-checkbox-container">
            <Checkbox />
          </div>
          <span className="agreement-description">
            Ich erklehre mich einverdtanden meinen Waschtag auch fur andere
            freizugeben
          </span>
        </div>
      </div>
      <div className="action-button">
        <Link to="/calendar">
          <Fab color="secondary" aria-label="add" className="action-button">
            <ArrowForward />
          </Fab>
        </Link>
      </div>
    </>
  );
};
