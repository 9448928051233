import './home-screen.css';
import logoImg from '../assets/img/logo.png';
import teaserBackground from '../assets/img/teaserBackground.png';
import teaserForeground from '../assets/img/teaserForeground.png';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
    Link
  } from "react-router-dom";    
import IconButton from '@mui/material/IconButton';

export const HomeScreen = () => (
    <div className='home-screen-page'>
        <header>
    	    <div className="app-logo">
                <div className="logo-label">
                    <span className="logo-part1">wisch</span><span className="logo-part2">wasch</span>
                </div>
                <div className="logo-image">
                    <img src={logoImg} alt="Logo bild" />
                </div>
            </div>
        </header>
        <div className="home-teaser">
            <img className='home-teaser-background' src={teaserBackground} alt="Logo bild" />
            <img className='home-teaser-foreground' src={teaserForeground} alt="Logo bild" />
        </div>
        <footer className="home-footer">
            <div className="home-container">
                <div className='home-stage'>
                    <div className='home-title'>
                        <div>Waschtag</div>
                        <div>war</div>
                        <div>Gestern!</div>
                    </div>
                    <div className='home-description'>
                        Verreist und Waschtag übersprungen?<br />
                        Das Cloud basierte Waschtag Heftli.
                    </div>
                </div>
            </div>
            <div className="next-screen">
                <Link to="/on-boarding">
                    <IconButton aria-label="Nachster screen" className='next-button' size="large">
                        <ArrowForwardIcon fontSize="inherit" />
                    </IconButton>
                </Link>
            </div>
        </footer>
    </div>
);