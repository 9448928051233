import "./calendar.css";
import "./fc-calendar-fix.css";

import FullCalendar, { DateSelectArg } from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { CalendarHeader } from "./calendar-header/calendar-header";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import { Link } from "react-router-dom";
import { CalendarEventActionDialog } from "./calendar-event-action-dialog/calendar-event-action-dialog";
import { createContext, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

export const CalendarContext = createContext({
  selectedDate: new Date(),
});

export const Calendar = () => {
  const [open, setOpen] = useState(false);
  const [eventDetail, setEventDetail] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  let navigate = useNavigate();

  const handleEditDialogClose = useCallback(
    (action: string) => {
      switch (action) {
        case "edit":
          navigate("/booking", {
            replace: true,
            state: {
              start: eventDetail?.start,
              end: eventDetail?.end,
            },
          });
          break;
      }
      setOpen(false);
    },
    [navigate, eventDetail]
  );
  const handleCalendarSelect = useCallback(
    (selectionDateRange: DateSelectArg) => {
      navigate("/booking", {
        replace: true,
        state: {
          start: selectionDateRange.start,
          end: selectionDateRange.end,
        },
      });
    },
    [navigate]
  );

  const calendarEventClicked = useCallback((event: any) => {
    setOpen(true);
    setEventDetail(event.el.fcSeg);
  }, []);

  return (
    <CalendarContext.Provider value={{ selectedDate }}>
      <div className="calendar-page">
        <CalendarHeader
          dateSelected={(selected) => setSelectedDate(selected)}
        />
        <CalendarEventActionDialog
          onClose={handleEditDialogClose}
          open={open}
        />
        <section aria-label="Kalenderauswahl">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="timeGridDay"
            editable={true}
            selectable={true}
            locale={"de-ch"}
            nowIndicator={true}
            height={"auto"}
            headerToolbar={false}
            select={handleCalendarSelect}
            longPressDelay={5}
            scrollTime={"08:00:00"}
            eventClick={calendarEventClicked}
            events={[
              {
                title: "Bewohner 1",
                date: new Date().setHours(16),
                color: "#DDEFFB",
                borderColor: "#65A5DF",
                textColor: "#1c5a92",
              },
              {
                title: "Bewohner 2",
                date: new Date().setHours(8),
                color: "#DCFCEF",
                borderColor: "#6AE1AE",
                textColor: "#20855a",
              },
              {
                title: "Bewohner 3",
                date: new Date().setHours(12),
                color: "#FCEADC",
                borderColor: "#FD9806",
                textColor: "#a26918",
              },
            ]}
          />
        </section>
        <nav className="action-button">
          <Link
            to="/booking"
            state={{
              start: selectedDate,
              end: selectedDate.setHours(selectedDate.getHours() + 2),
            }}
          >
            <Fab color="secondary" aria-label="add" className="action-button">
              <AddIcon />
            </Fab>
          </Link>
        </nav>
      </div>
    </CalendarContext.Provider>
  );
};
