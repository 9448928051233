import { useState } from "react";
import "./inline-multi-selector.css";

export const InlineMultiSelector = () => {
  const [selected, setSelected] = useState(0);

  return (
    <div className="inline-multi">
      <div className="inline-multi-title">Ihrer Waschtag:</div>
      <div className="inline-multi-container">
        {[...["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"]].map((value, index) => (
          <div
            className={
              "inline-multi-item" +
              (selected === index ? " inline-multi-selected" : "")
            }
            key={index}
            onClick={() => {
              setSelected(index);
            }}
          >
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};
