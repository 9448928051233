import "./qr-button.css";
import QrCodeImage from "../../assets/img/qr-code.png";

type Props = {
  onClick: () => void;
};

export const QrButton = (props: Props) => (
  <div
    className="onboarding-qr-button-container"
    onClick={() => props.onClick()}
  >
    <div className="onboarding-qr-button">
      <div className="qr-image-container">
        <img src={QrCodeImage} alt="Scann QR Code" />
      </div>
      <div className="qr-content-container">
        <div className="qr-content-container-line1">QR Code Scannen</div>
        <div className="qr-content-container-line2">
          Der sich auf der Waschmaschine befindet.
        </div>
      </div>
    </div>
  </div>
);
