import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";

export const CalendarBookingSelector = () => {
  const [selected, setSelected] = useState<number>(-1);
  const [dateTimeFrom, setDateTimeFrom] = useState<Date | null>(new Date());
  const [dateTimeTo, setDateTimeTo] = useState<Date | null>(new Date());
  const [timePickerDialogOpen, setTimePickerDialogOpen] = useState(false);

  return (
    <div className="calendar-booking-time-container">
      <div
        className={selected === -1 ? "calendar-day-selected" : ""}
        onClick={() => setSelected(-1)}
      >
        <div className={`calendar-day-item calendar-day-default`}>
          <div className="calendar-day-card calendar-booking-tile">
            06:00
            <br />
            bis
            <br />
            8:00
          </div>
          <div className="calendar-day-event-bullets"></div>
        </div>
      </div>
      <div
        className={selected === 0 ? "calendar-day-selected" : ""}
        onClick={() => setSelected(0)}
      >
        <div className={`calendar-day-item calendar-day-default`}>
          <div className="calendar-day-card calendar-booking-tile">
            08:00
            <br />
            bis
            <br />
            12:00
          </div>
          <div className="calendar-day-event-bullets"></div>
        </div>
      </div>
      <div
        className={selected === 1 ? "calendar-day-selected" : ""}
        onClick={() => setSelected(1)}
      >
        <div className={`calendar-day-item calendar-day-default`}>
          <div className="calendar-day-card calendar-booking-tile">
            12:00
            <br />
            bis
            <br />
            17:00
          </div>
          <div className="calendar-day-event-bullets"></div>
        </div>
      </div>
      <div>
        <div className={`calendar-day-item calendar-day-taken`}>
          <div className="calendar-day-card calendar-booking-tile">
            17:00
            <br />-<br />
            19:00
          </div>
          <div className="calendar-day-event-bullets"></div>
        </div>
      </div>
      <div
        className={selected === 3 ? "calendar-day-selected" : ""}
        onClick={() => setSelected(3)}
      >
        <div className={`calendar-day-item calendar-day-default`}>
          <div className="calendar-day-card calendar-booking-tile">
            19:00
            <br />-<br />
            22:00
          </div>
          <div className="calendar-day-event-bullets"></div>
        </div>
      </div>
      <div
        className="calendar-booking-chooser"
        onClick={() => setTimePickerDialogOpen(true)}
      >
        <div className="calendar-day-item calendar-day-action">
          <div className="calendar-day-card calendar-booking-tile">
            <AccessTimeIcon />
            Andere Zeit
          </div>
          <div className="calendar-day-event-bullets"></div>
        </div>
      </div>
      <Dialog
        open={timePickerDialogOpen}
        onClose={() => setTimePickerDialogOpen(false)}
      >
        <DialogTitle>Termin Buchen</DialogTitle>
        <DialogContent>
          <DialogContentText>Beitte wahlen Sie die Zeit</DialogContentText>

          <div className="booking-input-item">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="Von"
                ampm={false}
                value={dateTimeFrom}
                onChange={(newValue) => {
                  setDateTimeFrom(newValue);
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="booking-input-item">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                ampm={false}
                renderInput={(props) => <TextField {...props} />}
                label="Bis"
                value={dateTimeTo}
                onChange={(newValue) => {
                  setDateTimeTo(newValue);
                }}
              />
            </LocalizationProvider>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTimePickerDialogOpen(false)}>
            Abbrechen
          </Button>
          <Button onClick={() => setTimePickerDialogOpen(false)}>Buchen</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
