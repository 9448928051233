import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState } from "react";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

type Props = {
  start: string;
  end: string;
};

export const CalendarBookingTimeSelector = ({ start, end }: Props) => {
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);

  return (
    <div className="date-selection-container">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={3}>
          <TimePicker
            label="Von"
            value={startDate}
            ampm={false}
            onChange={(newValue: any) => {
              setStartDate(newValue);
            }}
            renderInput={(params: any) => <TextField {...params} />}
          />
          <TimePicker
            label="Bis"
            ampm={false}
            value={endDate}
            onChange={(newValue: any) => {
              setEndDate(newValue);
            }}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </Stack>
      </LocalizationProvider>
    </div>
  );
};
