import { BackNavHeader } from "../../shared/header/back-nav-header/back-nav-header";
import "./calendar-booking-page.css";
import bookingInto from "../../assets/img/bookingIntro.png";
import { Link, useLocation } from "react-router-dom";
import Fab from "@mui/material/Fab";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { CalendarBookingSelector } from "./calendar-booking-selector";
import { CalendarBookingTimeSelector } from "./calendar-booking-time-selector";
import { InfoPanel } from "../../shared/info-panel/info-panel";
import format from "date-fns/format";

export const CalendarBookingPage = (props: any) => {
  const locationParams: any = useLocation();

  return (
    <>
      <BackNavHeader backlink="/calendar">
        Waschtermin
        <br />
        buchen
      </BackNavHeader>
      <img className="booking-intro" src={bookingInto} alt="Intro Bild" />

      <InfoPanel
        title={format(locationParams?.state?.start, "dd, MMM yyyy")}
        children={"Waschmashine Rechts"}
      />

      <div className="time-selection-container">
        <div className="calendar-booking-title">Termin auswahlen</div>
        <br />
        {locationParams?.state?.end ? (
          <>
            <CalendarBookingTimeSelector
              start={locationParams?.state?.start}
              end={locationParams?.state?.end}
            />
          </>
        ) : (
          <CalendarBookingSelector />
        )}
      </div>

      <nav className="action-button">
        <Link to="/booking">
          <Fab color="secondary" aria-label="add" className="action-button">
            <SaveOutlinedIcon />
          </Fab>
        </Link>
      </nav>
    </>
  );
};
