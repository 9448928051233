import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useState } from 'react';

const today = new Date();

export const CalendarDayChooseItem = (props: {}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedDay, setSelectedDay] = useState<Date | null>(today);

    return (
        <>
        <div className="calendar-day-item calendar-day-choose calendar-day-card-action">
            <div className="calendar-day-card" onClick={() => setOpenDialog(true)}>
                  <DateRangeOutlinedIcon  />
            </div>
            <div className="calendar-day-event-bullets"></div>
        </div>
         <LocalizationProvider dateAdapter={AdapterDateFns}>
         <MobileDatePicker
             open = {openDialog}
             label="Datum waheln"
             inputFormat="MM/dd/yyyy"
             value={ selectedDay }
             onClose={ () => setOpenDialog(false) }
             onAccept = { () => setOpenDialog(false) }
             disableMaskedInput={ true }
             disablePast={ true }
             onChange={ (newValue: Date | null) => setSelectedDay(newValue) }
             renderInput={(params) => <></>}
         />
         </LocalizationProvider>
        </>
    );
}